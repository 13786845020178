<template>
  <v-app>
    <SnackbarAlert />
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { LayoutVertical, LayoutWrapper } from '@/components/layouts'
import SnackbarAlert from '@/components/alerts/SnackbarAlert'
import { AbilityBuilder, Ability } from '@casl/ability'
import GH from '@/util/helpers'

export default {
  components: {
    LayoutVertical,
    LayoutWrapper,
    SnackbarAlert
  },
  data () {
    return {}
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'vertical') return 'LayoutVertical'
      return 'LayoutWrapper'
    },
  },
  watch: {
    '$route': {
      handler () {
        if (this.$route.meta.isEnterpriseRequired) this.checkEnterpriseSelected()
      },
    },
  },
  created () {
    this.setAuth()
  },
  methods: {
    getLoginProfile() {
      this.$store
        .dispatch('fetchProfile', this.$keycloak)
    },
    setAbility() {
      // Initial permission
      const { can, rules } = new AbilityBuilder(Ability)
      this.$store.getters.getAbilities.map(item => can(item.action, item.subject))
      this.$ability.update(rules)
    },
    checkInitEnterpriseSelected() {
      if (GH.checkEnterpriseSelected()) {
        this.$store
          .dispatch('refreshGlobalEnterprise', JSON.parse(localStorage.getItem('enterprise')))
          .then(() => {
          })
          .catch((error) => {
            this.$log.error(error)
            GH.alertSnackbar(this.$store, error)
          })
      } else {
        GH.alertEnterpriseSelected(this.$store, this.$router)
      }
    },
    checkEnterpriseSelected() {
      if (!GH.checkEnterpriseSelected()) {
        GH.alertEnterpriseSelected(this.$store, this.$router)
      }
    },
    setAuth() {
      this.setAbility()
      this.getLoginProfile()
      this.checkInitEnterpriseSelected()
    }
  }
};
</script>
