import defineAbilities from './ability'
import store from '@/store'

export const canNavigate = to => {
  // $store.getters.getAbilities
  let lenRoute = to.matched.length
  const isMatch = to.matched.some(route => {
    const ability = defineAbilities(store.getters.getAbilities)
    // If don't have permission
    if (!ability.can(route.meta.action || 'initial', route.meta.resource)) return false
    // If last loop
    if (lenRoute === 1) return true
    lenRoute -= 1
  })
  return isMatch
}

export const _ = undefined
