<template>
  <div>
    <v-dialog
      v-model="loadingLogout"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Logging out
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar
      class="ma-5 rounded"
      color="primary"
      dark
      app
    >
      <v-app-bar-nav-icon @click="handleDrawerToggle" />
      <component :is="toolbar" />
      <v-spacer />
      <v-toolbar-items>
        <FullScreenToolbar />
        <v-menu
          offset-y
          origin="center center"
          transition="scale-transition"
        >
          <template #activator="{ on }">
            <v-btn
              slot="activator"
              icon
              large
              text
              v-on="on"
            >
              <Avatar
                :size="36"
                :username="getUsername"
                :src="getAvatar"
                :color="getUserColor"
                status="online"
              />
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item
              v-for="(item, index) in profileMenus"
              :key="index"
              :to="!item.href ? { name: item.name } : null"
              :href="item.href"
              :disabled="item.disabled"
              :target="item.target"
              rel="noopener"
              @click="item.click"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar
        v-if="extended && breadcrumbs.to"
        slot="extension"
        class="rounded-b"
        light
        dense
        elevation="0"
      >
        <div v-if="breadcrumbs">
          <v-hover v-slot="{ hover }">
            <div>
              <div v-if="hover">
                <router-link :to="breadcrumbs.to">
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ breadcrumbs.textTo }}
                </router-link>
              </div>
              <div 
                v-else 
                class="text--secondary"
              >
                <v-icon>
                  {{ breadcrumbs.icon }}
                </v-icon>
                {{ breadcrumbs.text }}
              </div>
            </div>
          </v-hover>
        </div>
        <v-spacer />
        <component :is="breadcrumbAction" />
      </v-toolbar>
    </v-app-bar>
  </div>
</template>
<script>
import Avatar from '@/components/avatar/Avatar'
// Toolbar
import FullScreenToolbar from './FullScreenToolbar.vue'
import PolicyEditToolbar from './PolicyEditToolbar.vue'
import DeviceListToolbar from './DeviceListToolbar.vue'
import CustomerListToolbar from './CustomerListToolbar.vue'
import PolicyListToolbar from './PolicyListToolbar.vue'
import PolicyAddToolbar from './PolicyAddToolbar.vue'
// Action
import PolicyEditAction from './breadcrumb/PolicyEditAction.vue'
import PolicyAddAction from './breadcrumb/PolicyAddAction.vue'
import PolicyQrSearchAction from './breadcrumb/PolicyQrSearchAction.vue'
import CustomerImportAction from './breadcrumb/CustomerImportAction.vue'
import PolicyCompareAction from './breadcrumb/PolicyCompareAction.vue'
import PolicyViewQrAction from './breadcrumb/PolicyViewQrAction.vue'
import PolicyViewAction from './breadcrumb/PolicyViewAction.vue'
import PolicyImportAction from './breadcrumb/PolicyImportAction.vue'
import RoleAddAction from './breadcrumb/RoleAddAction.vue'
import RoleViewAction from './breadcrumb/RoleViewAction.vue'
import RoleEditAction from './breadcrumb/RoleEditAction.vue'
import OrganisationAddAction from './breadcrumb/OrganisationAddAction.vue'
import OrganisationEditAction from './breadcrumb/OrganisationEditAction.vue'
import EnterpriseAddAction from './breadcrumb/EnterpriseAddAction.vue'
import EnterpriseEditAction from './breadcrumb/EnterpriseEditAction.vue'
import UserAddAction from './breadcrumb/UserAddAction.vue'
import UserViewAction from './breadcrumb/UserViewAction.vue'
import UserEditAction from './breadcrumb/UserEditAction.vue'
import ServiceAccountAddAction from './breadcrumb/ServiceAccountAddAction.vue'
import ServiceAccountEditAction from './breadcrumb/ServiceAccountEditAction.vue'
import { alertSnackbar, containParams } from '@/util/helpers'
import { mapGetters } from 'vuex'

export default {
  components: {
    Avatar,
    // Toolbar
    FullScreenToolbar,
    PolicyEditToolbar,
    DeviceListToolbar,
    CustomerListToolbar,
    PolicyListToolbar,
    PolicyAddToolbar,
    // Action
    PolicyEditAction,
    PolicyAddAction,
    PolicyQrSearchAction,
    CustomerImportAction,
    PolicyCompareAction,
    PolicyViewQrAction,
    PolicyViewAction,
    PolicyImportAction,
    RoleAddAction,
    RoleViewAction,
    RoleEditAction,
    OrganisationAddAction,
    OrganisationEditAction,
    EnterpriseAddAction,
    EnterpriseEditAction,
    UserAddAction,
    UserViewAction,
    UserEditAction,
    ServiceAccountAddAction,
    ServiceAccountEditAction,
  },
  props: {
    extended: Boolean,
  },
  data() {
    return {
      profileMenus: [
        {
          icon: 'mdi-logout',
          href: '#',
          title: 'Sign Out',
          click: this.handleLogut,
        },
      ],
      breadcrumbAction: null,
      loadingLogout: false,
    }
  },
  computed: {
    ...mapGetters(['getAvatar', 'getUsername', 'getUserColor']),
    toolbar() {
      return this.toolbarView()
    },
    breadcrumbs() {
      const route = this.$route
      if (route.meta.title) {
        return {
          text: route.meta.title,
          textTo: route.meta.breadcrumbsTitle,
          to: containParams(route.meta.breadcrumbRedirect) ? this.$store.getters.getRefreshBreadcrumbs : route.meta.breadcrumbRedirect,
          icon: route.meta.icon
        }
      }
      return null
    },
  },
  methods: {
    toolbarView() {
      this.breadcrumbAction = this.$route.meta.breadcrumbAction
      return this.$route.meta.toolbarAction
    },
    handleDrawerToggle() {
      this.$emit('side-icon-click')
    },
    handleLogut() {
      this.loadingLogout = true
      this.$store
        .dispatch('logout', this.$keycloak.tokenParsed.preferred_username)
        .then(() => {
          this.$store
            .dispatch('deactivateGlobalEnterprise')
            .then(() => {
              this.loadingLogout = false
              this.$keycloak.logout()
            })
            .catch((error) => {
              this.$log.error(error)
              this.loadingLogout = false
              alertSnackbar(this.$store, error)
            })
        })
        .catch((error) => {
          this.$log.error(error)
          this.loadingLogout = false
          alertSnackbar(this.$store, error)
        })
    },
  },
}
</script>