import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import { canNavigate } from '@/plugins/acl/routeProtection'
import error from './routes/error'
import enterprises from './routes/enterprises'
import policies from './routes/policies'
import dashboard from './routes/dashboard'
import devices from './routes/devices'
import roles from './routes/roles'
import customers from './routes/customers'
import zeroTouch from './routes/zero-touch'
import organisations from './routes/organisations'
import users from './routes/users'
import apps from './routes/apps'
import serviceAccounts from './routes/service-accounts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...error,
    ...dashboard,
    ...enterprises,
    ...zeroTouch,
    ...apps,
    ...serviceAccounts,
    ...customers,
    ...policies,
    ...devices,
    ...roles,
    ...organisations,
    ...users
  ],
})

router.beforeEach((to, from, next) => {
  if (!canNavigate(to)) {
  // Redirect to login if not logged in
    if (!Vue.$keycloak.authenticated) return Vue.$keycloak.login({ redirectUri: window.location.href })
    
    if (to.name !== 'not-authorized') {
      // If logged in => not authorized
      return next({ name: 'not-authorized' })
    }
  }

  return next()
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
  // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router