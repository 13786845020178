<template>
  <div>
    <v-dialog
      v-model="loadingLogout"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Logging out
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app>
      <v-container
        fluid
        fill-height
      >
        <v-app-bar app>
          <v-toolbar-title class="headline text-uppercase">
            <span>Beach MDM</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            @click="handleLogut"
          >
            <v-icon left>
              mdi-logout
            </v-icon>
            Sign Out
          </v-btn>
        </v-app-bar>
          
        <v-main>
          <HelloWorld />
        </v-main>
      </v-container>
    </v-app>
  </div>
</template>
  
<script>
import HelloWorld from './HelloWorld'
import { alertSnackbar } from '@/util/helpers'

export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  data: () => ({
    loadingLogout: false,
  }),
  methods: {
    handleLogut() {
      this.loadingLogout = true
      this.$store
        .dispatch('logout', this.$keycloak.tokenParsed.preferred_username)
        .then(() => {
          this.$store
            .dispatch('deactivateGlobalEnterprise')
            .then(() => {
              this.loadingLogout = false
              this.$keycloak.logout()
            })
            .catch((error) => {
              this.$log.error(error)
              this.loadingLogout = false
              alertSnackbar(this.$store, error)
            })
        })
        .catch((error) => {
          this.$log.error(error)
          this.loadingLogout = false
          alertSnackbar(this.$store, error)
        })
    },
  }
};
</script>