import Vue from 'vue'
import axios from 'axios'
import { normalizePageFilter } from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
}

const getters = {
}

const actions = {
  async listWifi({}, params) {
    const optParams = normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/wifi?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async createWifi({}, params) {
    const data = JSON.stringify(params.body)
    const config = {
      method: 'post',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/wifi`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async updateWifi({}, params) {
    const data = JSON.stringify(params.body)
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/wifi/${params.wifiId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async deleteWifi({}, params) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/wifi/${params.wifiId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },
}

const mutations = {
}

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}