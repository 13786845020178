export default [
  {
                
    path: '/service-accounts',
    name: 'service-accounts-list',
    meta: {
      layout: 'vertical',
      title: 'Service Accounts',
      icon: 'mdi-cloud-key',
      action: 'service_accounts_list'
    },
    component: () => import('@/views/service-accounts/List.vue'),
  },
  {
        
    path: '/service-accounts/add',
    name: 'service-accounts-create',
    meta: {
      layout: 'vertical',
      title: 'Add Service Account',
      icon: 'mdi-plus',
      action: 'service_accounts_create',
      hidden: true,
      breadcrumbAction: 'ServiceAccountAddAction',
      breadcrumbRedirect: '/service-accounts',
      breadcrumbsTitle: 'Service Accounts'
    },
    component: () => import('@/views/service-accounts/Add.vue'),
  },
  {
    path: '/service-accounts/:id/edit',
    name: 'service-accounts-update',
    meta: {
      layout: 'vertical',
      title: 'Edit Service Account',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'service_accounts_update',
      breadcrumbAction: 'ServiceAccountEditAction',
      breadcrumbRedirect: '/service-accounts',
      breadcrumbsTitle: 'Service Accounts'
    },
    component: () => import('@/views/service-accounts/Edit.vue'),
  },
]