import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  serviceAccounts: [],
  serviceAccount: {},
  // ServiceAccount add
  btnSaveServiceAccountAdd: null,
  btnSaveLoadingServiceAccountAdd: null,
  // ServiceAccount edit
  btnSaveServiceAccountEdit: null,
  btnSaveLoadingServiceAccountEdit: null,
}

const getters = {
  getServiceAccounts: state => state.serviceAccounts,
  getServiceAccount: state => state.serviceAccount,
  // ServiceAccount add
  getBtnSaveServiceAccountAdd: state => state.btnSaveServiceAccountAdd,
  getBtnSaveLoadingServiceAccountAdd: state => state.btnSaveLoadingServiceAccountAdd,
  // ServiceAccount edit
  getBtnSaveServiceAccountEdit: state => state.btnSaveServiceAccountEdit,
  getBtnSaveLoadingServiceAccountEdit: state => state.btnSaveLoadingServiceAccountEdit,
}

const actions = {
  async listServiceAccounts({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/service-accounts?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setServiceAccounts', response.data)
        return response.data
      })
  },

  async createServiceAccount({}, params) {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/service-accounts`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: params
    }
    return await axios(config)
  },

  async updateServiceAccount({}, params) {        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/service-accounts/${params.serviceId}`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: params.serviceAccount
    }
    return await axios(config)
  },

  async deleteServiceAccount({}, id) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/service-accounts/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  async viewServiceAccount({ commit }, id) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/service-accounts/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        commit('setServiceAccount', response.data)
        return response.data
      })
  },

  // ServiceAccount add button trigger
  async btnClickSaveServiceAccountAdd({ commit }, params) {
    commit('setBtnSaveServiceAccountAdd', params)
  },
  async btnClickSaveLoadingServiceAccountAdd({ commit }, params) {
    commit('setBtnSaveLoadingServiceAccountAdd', params)
  },

  // ServiceAccount edit button trigger
  async btnClickSaveServiceAccountEdit({ commit }, params) {
    commit('setBtnSaveServiceAccountEdit', params)
  },
  async btnClickSaveLoadingServiceAccountEdit({ commit }, params) {
    commit('setBtnSaveLoadingServiceAccountEdit', params)
  },
};

const mutations = {
  setServiceAccounts(state, serviceAccounts) {
    state.serviceAccounts = serviceAccounts
  },
  setServiceAccount(state, serviceAccount) {
    state.serviceAccount = serviceAccount
  },
  // ServiceAccount add
  setBtnSaveServiceAccountAdd(state, params) {
    state.btnSaveServiceAccountAdd = params
  },
  setBtnSaveLoadingServiceAccountAdd(state, params) {
    state.btnSaveLoadingServiceAccountAdd = params
  },
  // ServiceAccount edit
  setBtnSaveServiceAccountEdit(state, params) {
    state.btnSaveServiceAccountEdit = params
  },
  setBtnSaveLoadingServiceAccountEdit(state, params) {
    state.btnSaveLoadingServiceAccountEdit = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}