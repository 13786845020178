import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  policies: [],
  policyLogs: [],
  policy: {},
  // Button trigger
  // Policy edit
  btnJsonViewPolicyEdit: null,
  btnViewQrPolicyEdit: null,
  btnSavePolicyEdit: null,
  btnSaveLoadingPolicyEdit: null,
  // Policy add
  btnJsonViewPolicyAdd: null,
  btnSavePolicyAdd: null,
  btnSaveLoadingPolicyAdd: null,
  // Policy view QR
  btnSavePolicyViewQr: null,
  btnSaveLoadingPolicyViewQr: null,
  // Policy import
  btnSavePolicyImport: null,
  btnSaveLoadingPolicyImport: null,
  // Policy search QR
  btnSearchPolicyByQr: null,
  btnSearchPolicyByQrLoading: null,
};

const getters = {
  allPolicies: state => state.policies,
  getPolicy: state => state.policy,
  allPolicyLogs: state => state.policyLogs,
  // Policy edit
  getBtnJsonViewPolicyEdit: state => state.btnJsonViewPolicyEdit,
  getBtnViewQrPolicyEdit: state => state.btnViewQrPolicyEdit,
  getBtnSavePolicyEdit: state => state.btnSavePolicyEdit,
  getBtnSaveLoadingPolicyEdit: state => state.btnSaveLoadingPolicyEdit,
  // Policy add
  getBtnJsonViewPolicyAdd: state => state.btnJsonViewPolicyAdd,
  getBtnSavePolicyAdd: state => state.btnSavePolicyAdd,
  getBtnSaveLoadingPolicyAdd: state => state.btnSaveLoadingPolicyAdd,
  // Policy view QR
  getBtnSavePolicyViewQr: state => state.btnSavePolicyViewQr,
  getBtnSaveLoadingPolicyViewQr: state => state.btnSaveLoadingPolicyViewQr,
  // Policy import
  getBtnSavePolicyImport: state => state.btnSavePolicyImport,
  getBtnSaveLoadingPolicyImport: state => state.btnSaveLoadingPolicyImport,
  // Policy search QR
  getBtnSearchPolicyByQr: state => state.btnSearchPolicyByQr,
  getBtnSearchPolicyByQrLoading: state => state.btnSearchPolicyByQrLoading,
};

const actions = {
  async listPolicies({ commit }, params) {
    const optParams = GH.normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setPolicies', response.data)
        return response.data
      })
  },

  async hideShowPolicy(_, params) {        
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}:hideShow`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.body)
    }
    return await axios(config)
  },

  async exportListPolicies({ commit }, params) {
    const optParams = GH.normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies:export?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      responseType: 'blob',
      timeout: 0
    }
        
    return await axios(config)
      .then((response) => {
        return response
      })
  },

  async allListPolicies({ commit }, params) {
    const optParams = GH.normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies:all?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setPolicies', response.data)
        return response.data
      })
  },

  async listPolicyLogsByPolicyId({}, { options, enterpriseId, policyId }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/policies/${policyId}/logs?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async listPolicyLogs({ commit }, params) {
    const optParams = GH.normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies:logs?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setPolicyLogs', response.data)
        return response.data
      })
  },

  async viewPolicyLog({}, params) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}/logs/${params.logId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async deletePolicy({}, params) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  async createPolicy({}, params) {
    var data = JSON.stringify(params.body)
        
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async updatePolicy({}, params) {
    var data = JSON.stringify(params.body)
        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async viewPolicy({ commit }, params) {
    const optParams = GH.normalizeParams({
      isMergeParent: params.isMergeParent
    })

    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}?${optParams}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    
    return await axios(config)
      .then((response) => {
        commit('setPolicy', response.data)
        return response.data
      })
  },

  async viewPolicyByQr({}, params) {
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies:searchByQr?${GH.normalizeParams(params.body)}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json'
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async clonePolicy({}, params) {

    var data = new FormData()
    data.append('policyGoogleId', params.policyGoogleId)
    // Check if notes not null or undefined
    if (params.notes) data.append('notes', params.notes)
        
    var config = {
      method: 'post',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}:clone`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    return await axios(config)
  },

  async exportPolicy({}, params) {

    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}:export`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
  },

  // Policy edit button trigger
  async btnClickJsonViewPolicyEdit({ commit }, params) {
    commit('setBtnJsonViewPolicyEdit', params)
  },
  async btnClickViewQrPolicyEdit({ commit }, params) {
    commit('setBtnViewQrPolicyEdit', params)
  },
  async btnClickSavePolicyEdit({ commit }, params) {
    commit('setBtnSavePolicyEdit', params)
  },
  async btnClickSaveLoadingPolicyEdit({ commit }, params) {
    commit('setBtnSaveLoadingPolicyEdit', params)
  },

  // Policy add button trigger
  async btnClickJsonViewPolicyAdd({ commit }, params) {
    commit('setBtnJsonViewPolicyAdd', params)
  },
  async btnClickSavePolicyAdd({ commit }, params) {
    commit('setBtnSavePolicyAdd', params)
  },
  async btnClickSaveLoadingPolicyAdd({ commit }, params) {
    commit('setBtnSaveLoadingPolicyAdd', params)
  },

  // Policy view QR button trigger
  async btnClickSavePolicyViewQr({ commit }, params) {
    commit('setBtnSavePolicyViewQr', params)
  },
  async btnClickSaveLoadingPolicyViewQr({ commit }, params) {
    commit('setBtnSaveLoadingPolicyViewQr', params)
  },

  // Policy import button trigger
  async btnClickSavePolicyImport({ commit }, params) {
    commit('setBtnSavePolicyImport', params)
  },
  async btnClickSaveLoadingPolicyImport({ commit }, params) {
    commit('setBtnSaveLoadingPolicyImport', params)
  },

  // Policy search QR button trigger
  async btnClickSearchPolicyByQr({ commit }, params) {
    commit('setBtnSearchPolicyByQr', params)
  },
  async btnClickSearchPolicyByQrLoading({ commit }, params) {
    commit('setBtnSearchPolicyByQrLoading', params)
  },
};

const mutations = {
  setPolicies(state, policies) {
    state.policies = policies
  },
  setPolicyLogs(state, policyLogs) {
    state.policyLogs = policyLogs
  },
  setPolicy(state, policy) {
    state.policy = policy
  },
  // Policy edit
  setBtnJsonViewPolicyEdit(state, params) {
    state.btnJsonViewPolicyEdit = params
  },
  setBtnViewQrPolicyEdit(state, params) {
    state.btnViewQrPolicyEdit = params
  },
  setBtnSavePolicyEdit(state, params) {
    state.btnSavePolicyEdit = params
  },
  setBtnSaveLoadingPolicyEdit(state, params) {
    state.btnSaveLoadingPolicyEdit = params
  },
  // Policy add
  setBtnJsonViewPolicyAdd(state, params) {
    state.btnJsonViewPolicyAdd = params
  },
  setBtnSavePolicyAdd(state, params) {
    state.btnSavePolicyAdd = params
  },
  setBtnSaveLoadingPolicyAdd(state, params) {
    state.btnSaveLoadingPolicyAdd = params
  },
  // Policy view QR
  setBtnSavePolicyViewQr(state, params) {
    state.btnSavePolicyViewQr = params
  },
  setBtnSaveLoadingPolicyViewQr(state, params) {
    state.btnSaveLoadingPolicyViewQr = params
  },
  // Policy import
  setBtnSavePolicyImport(state, params) {
    state.btnSavePolicyImport = params
  },
  setBtnSaveLoadingPolicyImport(state, params) {
    state.btnSaveLoadingPolicyImport = params
  },
  // Policy search QR
  setBtnSearchPolicyByQr(state, params) {
    state.btnSearchPolicyByQr = params
  },
  setBtnSearchPolicyByQrLoading(state, params) {
    state.btnSearchPolicyByQrLoading = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}